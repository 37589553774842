import React from "react";

function Honoraires() {
  // Bannière avec le titre exact
  const Banner = () => (
    <div className="w-full text-center p-10 bg-gray-800">
      <h1
        className="text-3xl md:text-4xl font-Bayer md:mb-4 text-white  relative cursor-pointer transition-all duration-500
          before:content-[''] before:absolute before:bottom-[-15px] before:left-1/2 before:-translate-x-1/2
          before:w-0 before:h-0.5 before:rounded-full before:opacity-0 before:transition-all before:duration-500
          before:bg-gradient-to-r before:from-gray-200 before:via-gray-800 before:to-gray-200 hover:before:w-full hover:before:opacity-100"
      >
        Honoraires{" "}
      </h1>
    </div>
  );
  return (
    <div
      id="honoraires"
      className="flex flex-col items-center justify-center bg-gray-300 "
    >
      {/* Bannière avec le titre exact */}
      <Banner />

      <div className="md:w-2/3 font-avocats text-black p-12 md:px-8 md:py-24 text-justify">
        <p className="text-lg md:text-xl leading-relaxed text-center md:text-left tracking-wider">
          Les honoraires sont fixés selon la nature, la complexité et les
          spécificités du dossier. La question des honoraires est abordée dès le
          premier entretien.
        </p>
        <p className="mt-4 text-lg md:text-xl leading-relaxed text-center md:text-left tracking-wider">
          Une convention d’honoraires écrite est nécessairement établie
          conformément aux dispositions légales et déontologiques de la
          profession. Les honoraires peuvent être fixés de façon forfaitaire ou
          au temps passé selon le taux horaire du cabinet.
        </p>
        <p className="mt-4 text-lg md:text-xl leading-relaxed text-center md:text-left tracking-wider">
          Un honoraire de résultat peut être convenu en complément. Le coût de
          la première consultation est fonction de sa durée et de sa complexité.{" "}
        </p>
        <p className="mt-4 text-lg md:text-xl leading-relaxed text-center md:text-left tracking-wider">
          L’assurance protection juridique du client peut être actionnée et peut
          permettre une prise en charge partielle ou totale des honoraires.
        </p>
        <p className="mt-4 text-lg md:text-xl leading-relaxed text-center md:text-left tracking-wider">
          Selon le montant des ressources et le patrimoine du client, une prise
          en charge au titre de l’Aide Juridictionnelle peut être étudiée.
        </p>
      </div>
      <div className="w-full h-1 bg-gray-700 opacity-50 my-0"></div>
    </div>
  );
}

export default Honoraires;
