import React from "react";
import LawyerFirm from "../assets/chevalHotel.jpg"; // Image de fond à gauche
import LawyerFirm2 from "../assets/vueSaone.jpg"; // Image de fond à gauche

import ArnaudDepenau from "../assets/arnaudDepenau.jpg"; // Photo 1er avocat
import MarinePro from "../assets/marinePro3.jpg"; // Photo 2e avocat

import iconePhone from "../assets/phone1.png"; // Icône téléphone
import iconeLK from "../assets/iconeLK.png"; // Icône LinkedIn

import "./NavBar.css";

function Presentation() {
  const Banner = () => (
    <div className="w-full text-center p-10 bg-gray-800">
      <h1
        className="text-3xl md:text-4xl font-Bayer md:mb-4 text-white relative cursor-pointer transition-all duration-1000
        before:content-[''] before:absolute before:bottom-[-15px] before:left-1/2 before:-translate-x-1/2
        before:w-0 before:h-0.5 before:rounded-full before:opacity-0 before:transition-all before:duration-500
        before:bg-gradient-to-r before:from-gray-200 before:via-gray-800 before:to-gray-200 hover:before:w-full hover:before:opacity-100 "
      >
        Les avocats membres du Groupement
      </h1>
    </div>
  );

  const AvocatSection = ({
    name,
    image,
    description,
    domaines,
    backgroundImage,
    phone,
    linkedin,
  }) => {
    const descriptionParagraphs = description
      .split(/(?<!gestion des ressources humaines)\./)
      .map((sentence, index) => {
        if (sentence.trim()) {
          return (
            <p
              key={index}
              className="mb-4 leading-relaxed text-md md:text-md cursor-pointer"
            >
              {sentence.trim()}.
            </p>
          );
        }
        return null;
      });

    return (
      <div className="flex flex-col md:flex-row w-full min-h-screen">
        {/* Image de gauche */}
        <div className="hidden md:flex w-1/3 min-h-screen relative border-r-4 border-gray-400">
          <img
            src={backgroundImage}
            alt="Cabinet d'avocats"
            className="w-full h-full object-cover"
          />
        </div>

        {/* Contenu texte de droite */}
        <div className="w-full md:w-2/3 flex flex-col p-6 md:p-16 bg-gray-300 text-black min-h-screen">
          <h1 className="text-3xl md:text-4xl font-seasons uppercase font-semibold mb-4 text-center md:text-left relative text-gray-800">
            {name}
            <span className="block w-full h-0.5 bg-gray-400 mt-2"></span>
          </h1>

          <div className="flex flex-col md:flex-row items-center md:items-start">
            <img
              src={image}
              alt={name}
              className="w-48 h-64 object-contain rounded-xl mb-4 md:mb-0 md:mr-6 shadow-lg hover:scale-105 transition-transform duration-1000 cursor-pointer"
            />

            <div className="text-justify font-avocats space-y-2">
              {descriptionParagraphs}
            </div>
          </div>

          {/* Domaine de compétence */}
          <div className="mt-8">
            <h2 className="text-2xl text-gray-800 font-semibold font-seasons uppercase mb-2 relative">
              Domaines de compétences
              <span className="block w-full h-0.5 bg-gray-400 mt-2"></span>
            </h2>
            <ul className="mt-4 space-y-2 text-justify">
              {domaines.map((domaine, index) => {
                const [titre, ...description] = domaine.split(":");
                return (
                  <li key={index} className="text-sm md:text-md font-avocats">
                    <div className="flex items-center font-semibold">
                      <span className="w-4 h-4 bg-gray-400 rounded-full inline-block mr-3"></span>
                      {titre}
                    </div>
                    {description.length > 0 && (
                      <p className="ml-6 text-gray-700">
                        {description.join(":").trim()}
                      </p>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>

          {/* Section des icônes CENTRÉE EN BAS */}
          <div className="flex justify-start items-center space-x-6 mt-8 cursor-pointer">
            {/* 📞 Numéro de téléphone */}
            <div className="flex items-center space-x-2">
              <img src={iconePhone} alt="Téléphone" className="w-6 h-6" />
              <span className="text-md text-gray-800 font-semibold font-avocats">
                {phone}
              </span>
            </div>

            {/* 🔗 Lien LinkedIn */}
            <a
              href={linkedin}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-2"
            >
              <img src={iconeLK} alt="LinkedIn" className="w-6 h-6" />
              <span className="text-md text-blue-600 font-semibold hover:underline"></span>
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id="cabinet" className="bg-cover">
      <Banner />

      <AvocatSection
        name="Maître Marine BRUNIERE"
        image={MarinePro}
        backgroundImage={LawyerFirm}
        description="Avant de cofonder le GIE POLAJURIS, Maître Marine BRUNIERE a exercé plusieurs années aux côtés du Professeur Didier JEAN-PIERRE, spécialiste de la fonction publique.
        Au sein du groupement, elle intervient sur toutes les problématiques relatives au droit de la fonction publique et à la gestion des ressources humaines. Dans ce cadre, elle assiste tant les personnes et les établissements publics que les agents publics.
         Formée aux techniques de médiation, elle propose également un accompagnement dans la résolution amiable des conflits.
        Marine BRUNIERE est chargée d'enseignement à l'Université Jean-Moulin - Lyon 3."
        domaines={[
          "Discipline et déontologie de la fonction publique",
          "Accident de service et maladie professionnelle",
          "Carrière des agents publics",
          "Rémunération des agents publics",
          "Protection fonctionnelle",
          "Droit des agents contractuels",
          "Harcèlement moral",
          "Aide à la réalisation d’enquête administrative",
          "Accompagnement en médiation",
        ]}
        phone="06 43 44 23 80"
        linkedin="https://www.linkedin.com/in/marine-bruni%C3%A8re-337551135/"
      />

      <div className="w-full h-1 bg-gray-700 opacity-50 my-0"></div>

      <AvocatSection
        name="Maître Arnaud DEPENAU"
        image={ArnaudDepenau}
        backgroundImage={LawyerFirm2}
        description="Diplômé en droit des contrats publics à l’université de Strasbourg en 2015, et titulaire du Certificat d'aptitude à la profession d'avocat, Arnaud DEPENAU a exercé au sein d’un cabinet Lyonnais dédié à la sphère publique puis d’un cabinet lyonnais spécialisé dans l’accompagnement des professionnels de l’immobilier.
        Au sein du groupement, il exerce notamment en droit de l’urbanisme, de la commande publique, domanialité, propriétés publiques et police administrative.
        Arnaud DEPENAU est chargé d'enseignement à l'Université Jean-Moulin - Lyon 3"
        domaines={[
          "Droit de l’urbanisme et aménagement: Audit de permis de construire, contentieux et conseil en matière d’évolution des documents d’urbanisme, de permis de construire et autres autorisations d’urbanisme, contentieux des décisions de préemption, d’expropriation (contentieux administratif et judiciaire en fixation du prix).",

          "Droit de la commande publique: Passation et exécution de marchés publics et concession (référés précontractuels et contractuels, recours Tarn et Garonne), responsabilité des constructeurs (responsabilité décennale, garantie de parfait achèvement, responsabilité contractuelle).",

          "Propriétés publiques et domanialité publique: Gestion et valorisation du domaine public et privé, procédure de déclassement, dommages résultant de travaux ou d’ouvrages publics.",

          "Droit administratif général: Responsabilité administrative, contentieux des mesures de police administrative et autres décisions municipales / préfectorales, procédures de référés (liberté, suspension, référés spécifiques).",
        ]}
        phone="06 52 17 47 41"
        linkedin="https://www.linkedin.com/in/arnaud-depenau-1b3347aa/"
      />
    </div>
  );
}

export default Presentation;
