import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import NavBar from "./components/NavBar";
import Home from "./components/Home";
import PresentationMB from "./components/PresentationMB";
import Contact from "./components/Contact";
import Actualités from "./components/Actualités"; // 👈 Importation du composant
import Article from "./components/Article";

import Honoraires from "./pages/Honoraires";
import MentionsLegales from "./components/MentionsLegales";
import Footer from "./components/Footer";

function App() {
  return (
    <Router>
      <div>
        <div className="z-90">
          <NavBar />
        </div>

        {/* Contenu principal */}
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Home />
                <PresentationMB />
                <Honoraires />
                <Contact />
                <Footer />
              </>
            }
          />

          {/* Nouvelle route pour la page Actualités */}
          <Route path="/actualités" element={<Actualités />} />
          <Route path="/actualites/:slug" element={<Article />} />

          {/* Route pour les mentions légales */}
          <Route path="/mentionslegales" element={<MentionsLegales />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
