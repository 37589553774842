import React from "react";
import { useState } from "react";

import { Link } from "react-router-dom";
import imageArticle1 from "../assets/ImageArticle1.png";
import ImageArticle2 from "../assets/ImageArticle2.png";
import ImageArticle3 from "../assets/ImageArticle3.png";
import ImageArticle4 from "../assets/ImageArticle4.png";
import ImageArticle5 from "../assets/ImageArticle5.png";
import ImageArticle6 from "../assets/ImageArticle6.png";
import ImageArticle7 from "../assets/ImageArticle7.png";
import ImageArticle8 from "../assets/ImageArticle8.png";
import ImageArticle9 from "../assets/ImageArticle9.png";
import ImageArticle10 from "../assets/ImageArticle10.png";
import ImageArticle11 from "../assets/ImageArticle11.png";
import ImageArticle12 from "../assets/ImageArticle12.png";
import ImageArticle13 from "../assets/ImageArticle13.png";

// Liste des articles avec ajout d'un slug basé sur le titre
export const articles = [
  {
    id: 1,
    title: "Raccordement définitif au réseau électrique et violation du PLU",
    image: imageArticle1,
    summary:
      "Le maire ne peut pas refuser le raccordement définitif au réseau électrique...",
    createdAt: "10 janvier 2025", // Ajout de la date de création

    content: [
      {
        text: (
          <a
            href="https://www.legifrance.gouv.fr/ceta/id/CETATEXT000050754527?init=true&page=1&query=23PA02497&searchField=ALL&tab_selection=all"
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold underline"
          >
            Cour administrative d’appel, Paris, 3e chambre, 5 décembre 2024 – n°
            23PA02497
          </a>
        ),
      },
      {
        text: "Le maire ne peut pas refuser le raccordement définitif au réseau électrique d’un appartement issu d’une division réalisée en violation des règles du PLU imposant une taille minimale des logements (secteur soumis à la servitude de taille minimale des logements, STML), mais sans qu’une autorisation d’urbanisme ne soit nécessaire.",
      },
      { text: "Point de vigilance : ", bold: true },
      {
        text: "Le maire peut s’opposer au raccordement définitif au réseau d’électricité , d’eau, de gaz ou de téléphone des bâtiments, locaux ou installations qui, faute de disposer de l’autorisation d’urbanisme ou de l’agrément nécessaire, sont irrégulièrement construits ou transformés.",
      },
      {
        text: "Si dans l’arrêt cité le maire ne peut pas refuser le raccordement électrique du nouvel appartement créé, la division en cause peut notamment donner lieu à :",
      },
      {
        text: "• Le constat d’une infraction pénale (L.610-1 du code de l’urbanisme)",
        bold: true,
      },
      {
        text: "• Des poursuites civiles (L.480-14 du code de l’urbanisme)",
        bold: true,
      },
      {
        text: "• Une injonction sous astreinte de régulariser la situation (L.481-1 du code de l’urbanisme)",
        bold: true,
      },
    ],
  },
  {
    id: 2,
    title: "Permis de construire : notification des recours",
    image: ImageArticle2,
    summary:
      "La notification d’un recours contre un permis de construire faite à l’adresse de la société mentionnée sur le panneau d’affichage est régulière...",
    createdAt: "2 décembre 2024",
    content: [
      {
        text: (
          <>
            <a
              href="https://www.legifrance.gouv.fr/ceta/id/CETATEXT000050682747?init=true&page=1&query=488592&searchField=ALL&tab_selection=all"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold underline"
            >
              Conseil d’État, 28 novembre 2024, n° 488592:
            </a>
            <br />
            La notification d’un recours contre un permis de construire faite à
            l’adresse de la société mentionnée sur le panneau d’affichage est
            régulière quand bien même il ne s’agirait pas de celle de la société
            pétitionnaire, mentionnée dans l’acte contesté.
          </>
        ),
      },
      {
        text: "En l’occurrence, la notification avait été faite à l’adresse de la société assurant la maitrise d’œuvre de l’opération, mentionnée sur le panneau d’affichage et non à celle de la société pétitionnaire.",
      },
      {
        text: "Le Conseil d’Etat censure ici le raisonnement de la Cour administrative d’appel ayant jugé que dans la mesure où l’adresse exacte avait bien été indiquée dans les visas de l’acte attaqué et dans le formulaire de demande de permis de construire, la notification à l’adresse mentionnée sur le panneau d’affichage ne pouvait pas être regardée comme régulière.",
      },
      {
        text: "Cette décision traduit donc un assouplissement de l’appréciation de la recevabilité des recours par le juge.",
      },
      {
        text: (
          <>
            Pour vous abonner à la newsletter du cabinet et recevoir chaque
            l’actualité du droit de l’urbanisme,
            <a
              href="https://tally.so/r/n9JEEQ?source=site"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold underline"
            >
              cliquez ici
            </a>
            .
          </>
        ),
      },
    ],
    link: "https://www.legifrance.gouv.fr/ceta/id/CETATEXT000050682747?init=true&page=1&query=488592&searchField=ALL&tab_selection=all",
  },
  {
    id: 3,
    title: "OAP et autorisation d’urbanisme : quelle compatibilité ?",
    image: ImageArticle3,
    summary:
      "Le Conseil d’État précise la manière dont la compatibilité d’une autorisation d’urbanisme avec une orientation d’aménagement et de programmation doit être contrôlée...",
    createdAt: "23 novembre 2024",
    content: [
      {
        text: (
          <>
            <a
              href="https://www.legifrance.gouv.fr/ceta/id/CETATEXT000050591124?init=true&page=1&query=489066&searchField=ALL&tab_selection=all"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold underline"
            >
              Conseil d’État, 1ère – 4ème chambres réunies, 18 novembre 2024,
              n°489066{" "}
            </a>
          </>
        ),
      },
      {
        text: "Sans opérer de revirement, le Conseil d’Etat précise la manière dont la compatibilité d’une autorisation d’urbanisme avec une orientation d’aménagement et de programmation doit être contrôlée.",
      },
      {
        text: "Ainsi, dans sa décision du 18 novembre 2024, il juge qu’elle doit s’apprécier au regard des effets du projet sur les objectifs fixés par l’OAP à l’échelle de la zone à laquelle elle se rapporte (et non du seul tènement du projet).",
      },
      {
        text: (
          <>
            Pour rappel, la compatibilité d’une autorisation d’urbanisme avec
            une OAP implique qu’elle ne doit pas en compromettre la réalisation,
            contrarier ses objectifs (
            <a
              href="https://www.legifrance.gouv.fr/ceta/id/CETATEXT000022330370?init=true&page=1&query=320780&searchField=ALL&tab_selection=all"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold underline"
            >
              <b>Conseil d’Etat, 26 mai 2010, Dos Santos, n° 320780, B</b>
            </a>
            "). Cela laisse alors une marge d’appréciation relativement
            importante dans leur application.
          </>
        ),
      },
      {
        text: "La solution retenue le 18 novembre par le Conseil d’Etat était déjà directement évoquée des conclusions relatives à une décision du 8 juillet 2019 :",
      },
      {
        text: (
          <>
            <a
              href="https://www.legifrance.gouv.fr/ceta/id/CETATEXT000042999999?init=true&page=1&query=418292&searchField=ALL&tab_selection=all"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold underline"
            >
              <b>
                Conseil d’Etat, 9 juillet 2019, n°418292 M. C… 1ère et 4ème
                chambres réunies
              </b>
            </a>
          </>
        ),
      },
      {
        text: (
          <i>
            « dans le prolongement de votre décision Dos Santos, nous pensons
            qu’elle ne pourrait être appliquée comme une norme de droit dur mais
            seulement comme un objectif à l’échelle de la zone concernée qui ne
            pourrait conduire à rendre illégal un permis de construire que si le
            projet le contrariait réellement ».
          </i>
        ),
      },
      {
        text: "La jurisprudence commentée confirme donc l’analyse proposée quelques années plus tôt par le rapporteur public Charles Touboul.",
      },
    ],
    link: "https://www.legifrance.gouv.fr/ceta/id/CETATEXT000038759044?init=true&page=1&query=418292&searchField=ALL&tab_selection=all",
  },
  {
    id: 4,
    title: "Quelques rappels utiles sur la division primaire",
    image: ImageArticle4,
    summary:
      "La division primaire permet d'obtenir un permis de construire avant la division juridique du terrain. Récemment, la CAA de Nantes et le Conseil d'État ont apporté des précisions...",
    createdAt: "15 novembre 2024",
    content: [
      {
        text: "Par exception à la procédure de lotissement, la division d’une unité foncière (prévue au a) de l’article R. 442-1 du code de l’urbanisme), dite  » division primaire « , permet à un pétitionnaire de demander et d’obtenir un permis de construire sur une partie de l’unité foncière existante alors que la division du terrain n’est juridiquement pas réalisée, celle-ci étant destinée à être accomplie après l’obtention du permis de construire.",
      },
      {
        text: "En pratique cela se traduit généralement par que la signature d’un compromis ou d’une promesse de vente entre la société pétitionnaire et le vendeur, qui comporte en annexe un document d’arpentage faisant état de la division parcellaire à intervenir lors de la réalisation de la vente. La réalisation de la vente est alors subordonnée à l’obtention par la société pétitionnaire d’un permis de construire devenu définitif.",
      },
      {
        text: (
          <>
            La Cour administrative d’appel de Nantes vient récemment de le
            rappeler (
            <a
              href="https://www.legifrance.gouv.fr/ceta/id/CETATEXT000050375087?init=true&page=1&query=22NT00392&searchField=ALL&tab_selection=all"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold underline"
            >
              <b>
                Cour administrative d’appel, Nantes, 5e chambre, 15 Octobre 2024
                – n° 22NT00392
              </b>
            </a>
            ) en jugeant qu’en telle hypothèse, nul besoin d’obtenir une
            déclaration préalable ou un permis d’aménager (lorsque le terrain se
            situe à l’intérieur du périmètre d’un site patrimonial remarquable,
            dans les abords d’un ou de plusieurs monuments historiques, dans un
            site classé ou en instance de classement, ou dans une réserve
            naturelle).
          </>
        ),
      },
      {
        text: (
          <>
            Enfin, comme l’a très clairement énoncé le
            <a
              href="https://www.legifrance.gouv.fr/ceta/id/CETATEXT000042519135"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold underline"
            >
              <b> Conseil d’Etat le 12 novembre 2020</b>
            </a>
            , le respect des règles d’urbanisme doit être apprécié au regard de
            l’ensemble de l’unité foncière existant à la date à laquelle
            l’administration statue sur la demande (et non sur la seule partie
            détachée destinée à recevoir la construction), bien que cette
            dernière soit informée de la division à venir.
          </>
        ),
      },
      {
        text: "Attention, toutefois, interdiction de recourir à la division primaire lorsque le permis de construire porte sur la création d’une maison individuelle.",
      },
    ],
    link: "https://www.legifrance.gouv.fr/ceta/id/CETATEXT000050789012?init=true&page=1&query=divisionprimaire&searchField=ALL&tab_selection=all",
  },
  {
    id: "5",
    title: "Quelle autorisation d’urbanisme pour ma piscine ?",
    image: ImageArticle5,
    summary:
      "La construction d’une piscine sur une propriété privée est soumise à différentes règles en matière d’urbanisme. Selon les caractéristiques...",
    createdAt: "31 octobre 2024",
    content: [
      {
        text: "La construction d’une piscine sur une propriété privée est soumise à différentes règles en matière d’urbanisme. Selon les caractéristiques de la piscine et la localisation du projet, différentes contraintes peuvent être opposables.",
      },
      {
        text: (
          <>
            <p className="font-bold">
              1. Piscines non couvertes (sans abri fixe ou mobile)
            </p>
          </>
        ),
      },
      {
        text: "• Piscines de moins de 10 m² : Aucune formalité n’est requise, conformément à l’article (R421-2 du Code de l’urbanisme).",
      },
      {
        text: "• Piscines entre 10 et 100 m² : Une déclaration préalable de travaux est requise (article R421-9 du Code de l’urbanisme).",
      },
      {
        text: "• Piscines de plus de 100 m² : Un permis de construire est exigé en vertu de l’article R421-1 du Code de l’urbanisme.",
      },
      {
        text: (
          <>
            <p className="font-bold">2. Piscines couvertes</p>
          </>
        ),
      },
      {
        text: "• Piscine avec abri de moins de 1,80 mètre de hauteur : même réglementation que pour une piscine non couverte.",
      },
      {
        text: "• Piscines de plus de 10m² avec abri de plus de 1,80 mètre de hauteur : Un permis de construire est exigé en vertu de l’article R421-1 du Code de l’urbanisme.",
      },
      {
        text: (
          <>
            <p className="font-bold">3. Piscines hors-sol</p>
          </>
        ),
      },
      {
        text: "• Pour les piscines hors-sols installées temporairement (moins de 3 mois), sont exemptées de formalités en vertu de l’article R421-5 du Code de l’urbanisme.",
      },
      {
        text: "• Si la piscine hors-sol est installée pour une durée supérieure à 3 mois, le régime est le même que celui des piscines enterrées.",
      },
      {
        text: (
          <>
            <p className="font-bold">4. Piscine intérieure</p>
          </>
        ),
      },
      {
        text: "Une piscine construite à l’intérieur d’un bâtiment existant est dispensée d’autorisation d’urbanisme si les travaux n’affectent pas l’aspect extérieur du bâtiment. S’il n’y a pas de création d’ouverture supplémentaire, par exemple.",
      },
      {
        text: (
          <>
            <p className="font-bold">
              5. Plan Local d’Urbanisme (PLU) et servitudes
            </p>
          </>
        ),
      },
      {
        text: "Le PLU peut imposer des restrictions spécifiques concernant les constructions et aménagements, y compris les piscines. Il convient donc, avant de réaliser son projet, de s’assurer que la piscine est conforme aux règles du PLU (distance par rapport aux limites notamment).",
      },
      {
        text: "A titre d’exemple, dans la Métropole de Lyon, les règles relatives à la surface de pleine terre, ou aux espaces végétalisés à valoriser, peuvent s’opposer à la construction d’une piscine.",
      },
      {
        text: "Les plans de prévention des risques naturels et technologiques (inondation notamment) peuvent également réglementer l’installation des piscines en les interdisant dans les secteurs de risque.",
      },
      {
        text: (
          <>
            <p className="font-bold">6. Sanctions</p>
          </>
        ),
      },
      {
        text: "La construction d’une piscine sans les autorisations requises ou en méconnaissance des règles d’urbanisme est une infraction pouvant donner lieu à une condamnation pénale incluant amendes et jusqu’à l’obligation de démolir la construction non autorisée (articles L480-1 et suivants du Code de l’urbanisme).",
      },
      {
        text: "Pour une étude personnalisée sur la faisabilité de votre projet : a.depenau@depenau-avocat.fr – 06 52 17 47 41",
      },
    ],
  },
  {
    id: 6,
    title: "Cristallisation des règles d’urbanisme et lotissement",
    image: ImageArticle6,
    summary:
      "L’article L. 442-14 du Code de l’urbanisme prévoit un mécanisme de cristallisation des droits à bâtir spécifique au lotissement. Ainsi, les dispositions d’urbanisme sont stabilisées durant 5 années...",
    createdAt: "28 octobre 2024",
    content: [
      {
        text: (
          <>
            <a
              href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000037694107"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold underline"
            >
              L’article L. 442-14 du Code de l’urbanisme{" "}
            </a>
            prévoit un mécanisme de cristallisation des droits à bâtir
            spécifique au lotissement. Ainsi, les dispositions d’urbanisme sont
            stabilisées durant 5 années, à compter de la date de non-opposition
            lorsque le lotissement est soumis à déclaration préalable ou à
            compter de l’achèvement des travaux d’équipement lorsque le
            lotissement a été autorisé par un permis d’aménager.
          </>
        ),
      },
      {
        text: (
          <>
            Depuis une{" "}
            <a
              href="https://www.legifrance.gouv.fr/ceta/id/CETATEXT000045916381?isSuggest=true"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold underline"
            >
              décision du Conseil d’Etat du 13 juin 2022, « société La
              Gariguette »
            </a>
            , il est jugé que le pétitionnaire d’un permis de construire déposé
            sur un lot de lotissement autorisé par déclaration préalable ne peut
            pas bénéficier de la cristallisation des règles d’urbanisme
            applicables en vertu de l’article L. 442-14 du Code de l’urbanisme
            en l’absence de tout transfert de propriété ou de jouissance
            intervenu lors du dépôt de sa demande de permis.
          </>
        ),
      },
      {
        text: (
          <>
            Le Conseil d’Etat, dans sa{" "}
            <a
              href="https://www.legifrance.gouv.fr/ceta/id/CETATEXT000050388822?init=true&page=1&query=473828++&searchField=ALL&tab_selection=all"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold underline"
            >
              décision du 18 octobre 2024, n° 473828
            </a>
            vient d’apporter une précision importante en indiquant qu’il n’est
            pas nécessaire que le lot destiné à être bâti ait lui-même fait
            l’objet d’un transfert de propriété pour bénéficier de la
            cristallisation.
          </>
        ),
      },
      {
        text: (
          <>
            Comme l’indique le rapporteur public dans ses{" "}
            <a
              href="https://www.conseil-etat.fr/arianeweb/#/view-document/?storage=true"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold underline"
            >
              conclusions
            </a>
            , « ce n’est pas la totalité des lots qui doit être cédée et ce
            n’est pas nécessairement le lot à bâtir qui doit être cédé ».
          </>
        ),
      },
      {
        text: (
          <p className=" font-bold">
            {" "}
            En résumé, nul besoin d’acquérir le lot à bâtir pour bénéficier de
            la cristallisation des règles d’urbanisme. La cession d’un des lots
            créés suffit. :
          </p>
        ),
      },
      {
        text: "Attention, le rapporteur public, Laurent DOMINGO, ne manque pas de rappeler que si des lots n’étant pas destinés à accueillir de constructions peuvent être inclus dans un lotissement, il faut que leur inclusion soit nécessaire à la cohérence d’ensemble de l’opération et que la réglementation qui leur est applicable soit respectée.",
      },
      {
        text: "On ne peut donc pas inclure sans risque des lots dans le périmètre d’un lotissement dans le seul but de les céder pour bénéficier de la cristallisation.",
      },
      {
        text: "Dans le cas qui lui était soumis, le rapporteur public émet un doute sur l’intérêt de l’inclusion de 3 lots n’étant pas destinés à être bâtis dans le lotissement, mais constate que la déclaration préalable n’étant pas contestée, la question de sa légalité ne peut pas être discutée.",
      },
      {
        text: (
          <>
            <p className="underline font-bold">
              {" "}
              Conseil d’État, 10ème – 9ème chambres réunies, 18/10/2024, 473828
              :
            </p>
            <p className="italic">
              « 4. En second lieu, dès lors que la division foncière a été
              réalisée par le transfert en propriété ou en jouissance d’une
              partie au moins des lots dans le délai de validité de l’arrêté de
              non-opposition à déclaration préalable prévu par l’article R*
              424-18 du code de l’urbanisme cité au point 2, le bénéficiaire de
              cet arrêté peut se prévaloir, à l’occasion d’une demande de permis
              de construire, des droits attachés, en vertu de l’article L.
              442-14 du même code, au lotissement autorisé. Est sans incidence,
              à cet égard, la circonstance que le lot destiné à être bâti n’ait
              pas lui-même fait l’objet d’un transfert en propriété ou en
              jouissance ».
            </p>
          </>
        ),
      },
    ],
    link: "http://google.fr",
  },
  {
    id: 7,
    title:
      "Pas d’intérêt à agir pour la société locataire de l’immeuble à démolir",
    image: ImageArticle7,
    summary:
      "Conseil d'État, 1ère - 4ème chambres réunies, 16 octobre 2024, n° 475093 La qualité de locataire d’un immeuble existant, ",
    createdAt: "21 octobre 2024",
    content: [
      {
        text: (
          <>
            <a
              href="https://www.legifrance.gouv.fr/ceta/id/CETATEXT000050362240?juridiction=CONSEIL_ETAT&juridiction=COURS_APPEL&juridiction=TRIBUNAL_ADMINISTATIF&juridiction=TRIBUNAL_CONFLIT&page=1&pageSize=10&publiRecueil=PUBLIE&query=urbanisme&searchField=ALL&searchType=ALL&sortValue=DATE_DESC&tab_selection=cetat"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold underline"
            >
              Conseil d’État, 1ère – 4ème chambres réunies, 16 octobre 2024, n°
              475093.{" "}
            </a>
          </>
        ),
      },
      {
        text: (
          <p className="font-bold">
            {" "}
            La qualité de locataire d’un immeuble existant, implanté sur le
            terrain d’assiette du projet, ayant vocation à être démoli pour les
            besoins de sa réalisation, ne bénéficie pas d’un intérêt à agir
            contre le permis de construire en cause.
          </p>
        ),
      },
      {
        text: (
          <>
            En conséquence, le Conseil d’Etat juge dans le cas qui lui était
            soumis, que le permis de construire, par lui-même, n’était pas de
            nature à affecter directement les conditions d’occupation,
            d’utilisation ou de jouissance par la société du bien occupé, au
            sens des dispositions de l’article L. 600-1-2 du code de
            l’urbanisme.
          </>
        ),
      },
      {
        text: (
          <p className="italic font-semibold">
            “4. Pour juger que la société Genedis justifiait d’un intérêt lui
            donnant qualité pour agir à l’encontre du permis de construire
            délivré le 10 mai 2019, la cour s’est fondée sur les circonstances
            qu’elle se prévalait de sa qualité de locataire, en vertu d’un bail
            commercial […], de l’immeuble existant, implanté sur le terrain
            d’assiette du projet et ayant vocation à être démoli pour les
            besoins de sa réalisation […]. En admettant que la qualité de
            locataire de l’immeuble existant conférait à la société requérante
            un intérêt suffisant pour demander l’annulation pour excès de
            pouvoir du permis de construire litigieux, alors que ce permis, par
            lui-même, n’était pas de nature à affecter directement les
            conditions d’occupation, d’utilisation ou de jouissance par la
            société du bien occupé, au sens des dispositions de l’article L.
            600-1-2 du code de l’urbanisme, la cour administrative d’appel a
            inexactement qualifié les faits de l’espèce”
          </p>
        ),
      },
    ],
  },
  {
    id: 8,
    title: "Permis de construire – Pas de sursis successifs (L.600-5-1)",
    image: ImageArticle8,
    summary:
      "Il faut d’abord rappeler que lorsque le juge estime qu’un permis de construire initial est entaché d’un vice, il peut sursoir à statuer afin de permettre au pétitionnaire de régulariser son autorisation (article L.600-5-1 du code de l’urbanisme).",
    createdAt: "17 octobre 2024",
    content: [
      {
        text: (
          <>
            Il faut d’abord rappeler que lorsque le juge estime qu’un permis de
            construire initial est entaché d’un vice, il peut sursoir à statuer
            afin de permettre au pétitionnaire de régulariser son autorisation
            (article L.600-5-1 du code de l’urbanisme).
          </>
        ),
      },
      {
        text: (
          <p className="font-bold">
            Par sa décision du 14 octobre 2024, le Conseil d’Etat a estimé
            qu’aucune disposition légale ou règlementaire ne permet au juge de
            sursoir à statuer successivement pour la régularisation d’un même
            vice affectant un permis de construire.
          </p>
        ),
      },
      {
        text: (
          <>
            En d’autres termes, si le permis de construire de régularisation
            obtenu ne permet pas de régulariser le vice du permis de construire
            initial, alors le juge ne peut pas décider d’un nouveau sursis pour
            régulariser ce même vice.
          </>
        ),
      },
      {
        text: <>Il doit donc annuler le permis contesté.</>,
      },
      {
        text: (
          <>
            En revanche, si le permis de construire de régularisation est
            entaché d’un vice qui lui est propre, alors le juge peut à nouveau
            sursoir à statuer pour permettre la régularisation du projet.
          </>
        ),
      },
      {
        text: (
          <>
            <a
              href="https://www.legifrance.gouv.fr/ceta/id/CETATEXT000050349101?init=true&page=1&query=471936&searchField=ALL&tab_selection=all"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold underline"
            >
              Conseil d’État, Section, 14 octobre 2024, n° 471936
            </a>
          </>
        ),
      },
    ],
  },
  {
    id: 9,
    title: "Comment lutter contre la pénurie de logements ?",
    image: ImageArticle9,
    summary:
      "La proposition de loi du 15 décembre 2023 – visant à faciliter la transformation des bâtiments de destination autre qu’habitation en habitations – a été transmise à l’Assemblée Nationale le 23 juillet dernier et doit revenir prochainement sur le bureau de la commission des affaires économiques.",
    createdAt: "16 octobre 2024",
    content: [
      {
        text: (
          <>
            La proposition de loi du 15 décembre 2023 – visant à faciliter la
            transformation des bâtiments de destination autre qu’habitation en
            habitations – a été transmise à l’Assemblée Nationale le 23 juillet
            dernier et doit revenir prochainement sur le bureau de la commission
            des affaires économiques.
          </>
        ),
      },
      {
        text: <>Celle-ci prévoit notamment la création de deux mécanismes :</>,
      },
      {
        text: (
          <>
            1/ Permettre à l’autorité qui délivre les permis de construire (le
            plus souvent le maire) d’autoriser le changement de destination d’un
            bâtiment ayant une destination autre que l’habitation (bureau par
            exemple) en bâtiment à destination principale d’habitation, en
            dérogeant aux règles relatives aux destinations du plan local
            d’urbanisme (PLU).
          </>
        ),
      },
      {
        text: (
          <>
            Dit plus simplement, cette proposition vise à permettre les
            changements de destination vers l’habitation dans des secteurs où le
            PLU ne les autorise pas. L’autorisation de changement de destination
            ne pourrait être accordée qu’avec l’accord de l’autorité compétente
            en matière de PLU. Le maire devrait alors lui transmettre une
            demande de dérogation dans un délai d’un mois à compter du dépôt de
            la demande de PC (ou DP). L’autorité compétente en matière de PLU
            aurait alors trois mois pour se prononcer. Sans réponse de sa part,
            la dérogation au PLU serait accordée.
          </>
        ),
      },
      {
        text: (
          <>
            2/ Créer un permis de construire à destinations
            multiples/successives : l’autorité compétente en matière de PLU
            pourrait, après avis conforme du conseil municipal si les communes
            concernées ne sont pas compétentes en matière de PLU, créer des
            secteurs dans lesquels un permis de construire porterait sur
            plusieurs destinations.
          </>
        ),
      },
      {
        text: (
          <>
            Cette autorisation devrait permettre aux surfaces d’un bâtiment
            d’évoluer simplement entre plusieurs destinations, à compter de sa
            délivrance et ce durant 10 ans. Il suffirait alors d’en informer le
            maire au moins trois mois avant le changement effectif de
            destination.
          </>
        ),
      },
      {
        text: (
          <>
            Ce changement de destination resterait possible même dans le cas où
            le document d’urbanisme applicable évoluerait ultérieurement et
            modifierait la liste des destinations autorisées dans le secteur.
          </>
        ),
      },
      {
        text: (
          <>
            La discussion de cette proposition de loi avait été interrompue par
            la dissolution. Son nouveau dépôt auprès de l’Assemblée nationale
            dans les jours suivants l’ouverture de la 17ème législature augure
            peut-être d’une issue prochaine et favorable…
          </>
        ),
      },
      {
        text: (
          <>
            <a
              href="https://www.assemblee-nationale.fr/dyn/16/dossiers/transformation_bureaux_logements"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold underline"
            >
              Cliquez ici pour accéder au dossier législatif
            </a>
          </>
        ),
      },
    ],
  },
  {
    id: 10,
    title:
      "Foisonnement et mutualisation des stationnements dans la ZAC Ferney-Genève-Innovation",
    image: ImageArticle10,
    summary:
      "Le tribunal administratif de Lyon vient pour la première fois de se prononcer sur l’application des dispositions du PLUi-H du Pays de Gex relatives à la mutualisation et au foisonnement des stationnements au sein de la ZAC Ferney-Genève-Innovation.",
    createdAt: "1er octobre 2024",
    content: [
      {
        text: (
          <>
            Le tribunal administratif de Lyon vient pour la première fois de se
            prononcer sur l’application des dispositions du PLUi-H du Pays de
            Gex relatives à la mutualisation et au foisonnement des
            stationnements au sein de la ZAC Ferney-Genève-Innovation.
          </>
        ),
      },
      {
        text: (
          <>
            Sur le foisonnement, le PLUiH énonce que :
            <br /> <br />
            <p className="italic font-bold">
              « Si les places de stationnement groupées d’un projet à
              destinations multiples peuvent faire l’objet d’occupations
              alternatives, c’est-à-dire si chaque place peut être affectée à
              des usages différents selon les heures et jours de la semaine, les
              places réglementaires peuvent ne pas être exigées, en tout ou
              partie ».
            </p>
          </>
        ),
      },
      {
        text: (
          <>
            Et la mutualisation est définie par le document d’urbanisme comme le
            fait de « rassembler dans un même dispositif de stationnement les
            besoins de plusieurs projets immobiliers proches ». Le PLUiH prévoit
            que l’obligation de stationnement peut être réduite d’au maximum 30
            % dans le cadre d’une démarche de mutualisation et de foisonnement.
          </>
        ),
      },
      {
        text: (
          <>
            La société pétitionnaire prévoyait de réaliser 77 logements et se
            prévalait d’un droit d’usage de 25 places au sein d’un parking
            mutualisé entre plusieurs lots de la ZAC. Elle estimait en
            conséquence pouvoir bénéficier d’une réduction de son obligation de
            stationnement à hauteur de 30 %.
          </>
        ),
      },
      {
        text: (
          <>
            Le tribunal administratif ne remet pas directement en cause le
            principe de la réalisation de places au sein du parking mutualisé
            mais estime que:
            <p className="italic font-bold">
              la réduction de 30 % ne peut pas s’appliquer au nombre de places
              d’une destination donnée mais au cumul de places avec une autre
              destination.
            </p>
          </>
        ),
      },
      {
        text: (
          <>
            Il précise, comme le prévoit le PLUiH, que si un projet ne comprend
            qu’une destination, une mutualisation est possible avec un autre
            projet. Il confirme en l’occurrence le refus de permis opposé par la
            commune car la société pétitionnaire s’était limitée à faire mention
            du parking mutualisé et de la perspective d’un foisonnement pour
            bénéficier de la réduction de 30 %.
          </>
        ),
      },
      {
        text: (
          <>
            <p className="font-bold">
              {" "}
              Pour bénéficier de la réduction de 30 % :
            </p>
            <ul>
              <br />

              <li>
                1 - Il faut d’abord viser précisément les destinations
                auxquelles le foisonnement s’applique.
              </li>
              <br />

              <li>
                2 - Si le projet ne comprend qu’une destination, une
                mutualisation est possible avec un autre projet qu’il faut
                clairement désigner.
              </li>
              <br />

              <li>
                3 - Il faut pouvoir justifier de l’utilisation alternative des
                places entre les destinations et estimer la réduction de
                l’obligation que cela permet (dans la limite de 30 %).
              </li>
              <br />

              <li>
                4- La réduction de 30 % maximum s’applique au besoin de
                stationnement réglementaire cumulé entre les destinations
                faisant l’objet du foisonnement.
              </li>
              <br />

              <li>
                En cas de mutualisation et foisonnement entre plusieurs projets,
                les besoins réglementaires en stationnement de chaque opération
                doivent donc être préalablement connus, ce qui peut, en
                pratique, rendre le mécanisme plus difficile à mettre en œuvre…
              </li>
            </ul>
          </>
        ),
      },
      {
        text: (
          <>
            <a
              href="http://google.fr"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold underline"
            >
              Ref. : Tribunal administratif de Lyon, 24 septembre 2024, n°
              2310637
            </a>
          </>
        ),
      },
    ],
  },
  {
    id: 11,
    title: "Permis de construire modificatif, peut-on tout changer ?",
    image: ImageArticle11,
    summary:
      "Depuis une décision du Conseil d’Etat en date du 26 juillet 2022 (n° 437765), les conditions d’octroi d’un permis de construire modificatif ont été assouplies.",
    createdAt: "16 septembre 2024",
    content: [
      {
        text: (
          <>
            Depuis une décision du Conseil d’Etat en date du 26 juillet 2022 (n°
            437765), les conditions d’octroi d’un permis de construire
            modificatif ont été assouplies.
          </>
        ),
      },
      {
        text: (
          <>
            Alors qu’auparavant il était impossible de remettre en cause la «
            conception générale » d’un projet, désormais il faut seulement que
            les modifications envisagées « n’apportent pas à ce projet un
            bouleversement tel qu’il en changerait la nature même ».
          </>
        ),
      },
      {
        text: (
          <>
            <p className="font-bold">
              La question qui se pose alors est savoir quand atteint-on le seuil
              du changement de nature du projet ?
            </p>
          </>
        ),
      },
      {
        text: (
          <>
            La Cour administrative d’appel de Paris a eu à se prononcer sur le
            sujet et a jugé que la création de trois logements supplémentaires,
            au sein d’une maison individuelle, la transformait en logement
            collectif et caractérisait un changement de nature, rendant alors
            impossible le recours au permis de construire modificatif.
          </>
        ),
      },
      {
        text: (
          <>
            <a
              href="https://www.legifrance.gouv.fr/ceta/id/CETATEXT000049719550?isSuggest=true"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold underline"
            >
              CAA de PARIS, 1ère chambre, 13 juin 2024, n° 23PA02307
            </a>
          </>
        ),
      },
    ],
  },
  {
    id: 12,
    title: "Limites à la Substitution parking / vélo – Art. L.152-6-1",
    image: ImageArticle12,
    summary:
      "Depuis le 25 août 2021, une dérogation à l’obligation de prévoir les aires de stationnement imposées par le plan local d’urbanisme (PLU) est possible, en contrepartie de la création d’espaces dédiés au stationnement des vélos (article L.152-6-1 du code de l’urbanisme) :",
    createdAt: "17 août 2024",
    content: [
      {
        text: (
          <>
            Depuis le 25 août 2021, une dérogation à l’obligation de prévoir les
            aires de stationnement imposées par le plan local d’urbanisme (PLU)
            est possible, en contrepartie de la création d’espaces dédiés au
            stationnement des vélos (article L.152-6-1 du code de l’urbanisme) :
          </>
        ),
      },
      {
        text: (
          <>
            <p className="font-bold italic">
              « En tenant compte de la nature du projet et de la zone
              d’implantation, l’autorité compétente pour délivrer le permis de
              construire peut, par décision motivée, lorsque le règlement du
              plan local d’urbanisme ou du document en tenant lieu impose la
              réalisation d’aires de stationnement pour les véhicules motorisés,
              réduire cette obligation à raison d’une aire de stationnement pour
              véhicule motorisé en contrepartie de la création d’infrastructures
              ou de l’aménagement d’espaces permettant le stationnement sécurisé
              d’au moins six vélos par aire de stationnement ».
            </p>
          </>
        ),
      },
      {
        text: (
          <>
            En résumé, si la dérogation est accordée, il est possible de
            substituer des espaces ou infrastructures pour garer des vélos aux
            places de stationnement automobile, à raison d’une place contre des
            emplacements pour 6 vélos.
          </>
        ),
      },
      {
        text: (
          <>
            <p className="font-bold">
              A rebours de certains tribunaux administratifs (1) la Cour
              administrative d’appel de Lyon a récemment jugé que ces
              dispositions n’autorisaient pas le pétitionnaire à dispenser son
              projet de la création de la totalité des places de stationnement
              requises par le PLU pour les véhicules motorisés.
            </p>
          </>
        ),
      },
      {
        text: (
          <>
            <a
              href="https://www.legifrance.gouv.fr/ceta/id/CETATEXT000049936633?init=true&page=1&query=23LY00291&searchField=ALL&tab_selection=all"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold underline"
            >
              Cour administrative d’appel de Lyon, 2 juillet 2024, n° 23LY00291
            </a>
          </>
        ),
      },
      {
        text: (
          <>
            Elle interprète donc strictement l’article L152-6-1 précité qui
            évoque seulement une réduction et non une suppression de
            l’obligation de réaliser des aires de stationnement pour les
            véhicules motorisés.
          </>
        ),
      },
      {
        text: (
          <>
            En pratique, lorsque l’on entend se prévaloir de cette dérogation,
            la prudence commande donc de toujours conserver quelques places des
            stationnement automobile.
          </>
        ),
      },
      {
        text: (
          <>
            <p className="italic">
              (1) Tribunal administratif, Lyon, 4 juillet 2024 – n° 2307678 ;
              Tribunal administratif, Melun, 22 mars 2024 – n° 2200426 ; –
              Tribunal administratif, Grenoble, 22 novembre 2022 – n° 2107860 ;
            </p>
          </>
        ),
      },
    ],
  },
  {
    id: 13,
    title: "Le sort des travaux irréguliers",
    image: ImageArticle13,
    summary:
      "Par sa décision « Sormonte » du 26 novembre 2018 (req. n°411991), le Conseil d’Etat faisait évoluer le régime juridique applicable aux travaux réalisés en méconnaissance d’une autorisation d’urbanisme.",
    createdAt: "11 décembre 2019",
    content: [
      {
        text: (
          <>
            Par sa décision « Sormonte » du 26 novembre 2018 (
            <a
              href="https://www.legifrance.gouv.fr/ceta/id/CETATEXT000037649056?init=true&page=1&query=411991&searchField=ALL&tab_selection=all"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold underline"
            >
              req. n°411991
            </a>
            ), le Conseil d’Etat faisait évoluer le régime juridique applicable
            aux travaux réalisés en méconnaissance d’une autorisation
            d’urbanisme. La Cour de cassation les saisit à son tour, avec le
            droit pénal (
            <a
              href="https://www.legifrance.gouv.fr/juri/id/JURITEXT000039621725/"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold underline"
            >
              Cour de cassation, Chambre criminelle, 3 décembre 2019, 18-86.032,
              Publié au bulletin
            </a>
            ).
          </>
        ),
      },
      {
        text: (
          <>
            Le Conseil d’Etat jugeait qu’une fois le délai de récolement passé
            (trois ou cinq mois), la conformité des travaux ne pouvait plus être
            remise en cause lors du dépôt de nouvelles autorisations d’urbanisme
            :
          </>
        ),
      },
      {
        text: (
          <>
            <p className="font-bold italic">
              « lorsque le bénéficiaire d’un permis ou d’une décision de
              non-opposition à déclaration préalable a adressé au maire une
              déclaration attestant l’achèvement et la conformité des travaux
              réalisés en vertu de cette autorisation, l’autorité compétente ne
              peut plus en contester la conformité au permis ou à la déclaration
              si elle ne l’a pas fait dans le délai, suivant les cas, de trois
              ou de cinq mois ni, dès lors, sauf le cas de fraude, exiger du
              propriétaire qui envisage de faire de nouveaux travaux sur la
              construction qu’il présente une demande de permis ou dépose une
              déclaration portant également sur des éléments de la construction
              existante, au motif que celle-ci aurait été édifiée sans respecter
              le permis de construire précédemment obtenu ou la déclaration
              préalable précédemment déposée ».
            </p>
          </>
        ),
      },
      {
        text: (
          <>
            Par son arrêt daté du 3 décembre 2019 (pourvoi n°18-86032), la Cour
            de cassation n’a pas tardé à se prononcer sur l’incidence de
            l’évolution de la jurisprudence administrative. Elle juge que
            l’absence de contestation de la déclaration d’achèvement et de
            conformité des travaux est sans effet sur la caractérisation de
            l’infraction de construction en méconnaissance d’un permis de
            construire sanctionnée par l’article L.480-4 du code de l’urbanisme
            :
          </>
        ),
      },
      {
        text: (
          <>
            <p className="font-semibold italic">
              « Attendu que, pour déclarer M. X. coupable de construction en
              violation du permis de construire délivré, l’arrêt énonce que le
              prévenu avait obtenu une autorisation pour édifier deux logements
              et qu’il ne peut contester en avoir réalisé trois ; que les juges
              ajoutent que cette modification affectait une construction qui
              n’avait pas encore été achevée conformément au permis de
              construire obtenu ; <br />
              <br />
              Attendu qu’en statuant ainsi, et dès lors que ni la déclaration
              d’achèvement de conformité des travaux ni l’attestation certifiant
              que la conformité des travaux avec le permis n’a pas été
              contestée, documents qui n’ont pas d’effet sur l’action publique,
              ne pouvaient conduire le juge à considérer que l’autorisation
              donnée avait épuisé ses effets et que la construction d’un
              troisième logement non prévu au permis de construire était
              constitutif de travaux nouveaux sur existant, au besoin dispensés
              de permis de construire, la cour d’appel a justifié sa décision ;
              »
            </p>
          </>
        ),
      },

      {
        text: (
          <>
            En d’autres termes, la conformité administrative dont bénéficient
            des constructions réalisées en méconnaissance d’un permis de
            construire depuis la décision « Sormonte », ne fait pas obstacle à
            la mise en mouvement de l’action publique et à des sanctions
            pénales.
          </>
        ),
      },
    ],
  },
];

// Nombre d'articles par page
const ARTICLES_PAR_PAGE = 6;

const generateSlug = (title) => {
  return title
    .normalize("NFD") // Supprime les accents
    .replace(/[\u0300-\u036f]/g, "") // Supprime les diacritiques
    .replace(/[^\w\s-]/g, "") // Supprime les emojis et caractères spéciaux
    .trim()
    .replace(/\s+/g, "-") // Remplace les espaces par des tirets
    .toLowerCase();
};

const articlesWithSlugs = articles.map((article) => ({
  ...article,
  slug: generateSlug(article.title),
}));

// Carte d'un article
function ArticleCard({ article }) {
  return (
    <div className="bg-white border cursor-pointer border-gray-200 rounded-lg shadow-md overflow-hidden w-full p-4 transition transform hover:scale-[1.02]">
      {/* Image */}
      <img
        src={article.image}
        alt={article.title}
        className="w-full h-48 object-contain"
      />

      {/* Contenu */}
      <div className="p-6 flex flex-col font-avocats">
        <h2 className="text-lg font-semibold text-gray-800">{article.title}</h2>
        <p className="text-gray-600 mt-2 line-clamp-3">{article.summary}</p>
        {/* Date de création */}
        <p className="text-gray-500 text-sm mt-2">{article.createdAt}</p>

        {/* Lien vers l'article avec le slug */}
        <Link
          to={`/actualites/${article.slug}`}
          className="mt-4 px-4 py-2 bg-black text-white font-semibold font-avocats rounded-lg hover:bg-navyBlue transition self-center text-center"
        >
          Lire l'article
        </Link>
      </div>
    </div>
  );
}

// Composant principal
function Actualités() {
  const [page, setPage] = useState(1);

  // Calcul du nombre total de pages
  const totalPages = Math.ceil(articlesWithSlugs.length / ARTICLES_PAR_PAGE);

  // Extraction des articles à afficher pour la page actuelle
  const indexDébut = (page - 1) * ARTICLES_PAR_PAGE;
  const articlesAffichés = articlesWithSlugs.slice(
    indexDébut,
    indexDébut + ARTICLES_PAR_PAGE
  );

  return (
    <div id="actualités" className="p-10 bg-gray-100 ">
      <h1 className="text-3xl font-bold text-center mb-12 mt-20 md:mt-24">
        Actualités du Groupement
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-10 px-4 max-w-7xl mx-auto">
        {articlesAffichés.map((article) => (
          <ArticleCard key={article.id} article={article} />
        ))}
      </div>

      {/* Pagination */}
      <div className="flex justify-center items-center mt-6 space-x-4 font-avocats">
        <button
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
          className={`px-4 py-2 rounded-lg text-md  ${
            page === 1
              ? "text-gray-400 cursor-not-allowed"
              : "text-gray-600 hover:bg-gray-200"
          }`}
        >
          « Précédent
        </button>

        <span className="text-md text-gray-600 ">
          {page} sur {totalPages}
        </span>

        <button
          onClick={() => setPage(page + 1)}
          disabled={page === totalPages}
          className={`px-4 py-2 rounded-lg text-md ${
            page === totalPages
              ? "text-gray-400 cursor-not-allowed"
              : "text-gray-600 hover:bg-gray-200"
          }`}
        >
          Suivant »
        </button>
      </div>
    </div>
  );
}

export { articlesWithSlugs }; // Export pour Article.jsx
export default Actualités;
