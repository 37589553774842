import React, { useState } from "react";
import emailjs from "emailjs-com";
import LogoKevinWhite from "../assets/LogoRemove.png";
import Map from "./Map.jsx";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_p4g3yzj", // Service ID
        "template_wf73ira", // Template ID
        formData,
        "or-4HYFYBcfoSC6G-" // Ta Public Key
      )
      .then(
        (response) => {
          console.log("Email envoyé avec succès", response);
          setSuccessMessage("Votre message a bien été envoyé !");
          setErrorMessage("");
          setFormData({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
          });
        },
        (error) => {
          console.error("Erreur lors de l'envoi de l'email", error);
          setErrorMessage("Une erreur est survenue, veuillez réessayer.");
          setSuccessMessage("");
        }
      );
  };

  return (
    <div className="flex flex-col">
      <div
        id="contact"
        name="contact"
        className="w-full min-h-screen flex flex-col lg:flex-row items-center justify-center bg-gray-300 text-limeGreen"
      >
        <div className="mt-8 max-w-screen-lg lg:w-1/3 mx-auto p-4 flex flex-col justify-center w-full h-full pb-8 cursor-pointer">
          <div className="pb-8 text-navyBlue">
            <p className="md:text-4xl text-3xl font-seasons font-bold uppercase inline border-b-4 border-navyBlue p-2">
              Contact
            </p>
            <p className="py-6 text-navyBlue font-avocats text-xl">
              Remplissez le formulaire pour nous faire part de votre situation
            </p>
          </div>
          <div className="flex justify-center items-center">
            <form
              onSubmit={handleSubmit}
              className="flex flex-col w-full md:w-full font-trebuc"
            >
              <input
                type="text"
                name="name"
                placeholder="Entrez votre nom"
                className="p-2 bg-transparent border-2 border-navyBlue rounded-md focus:outline-none mb-4"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Entrez votre mail"
                className="p-2 bg-transparent border-2 border-navyBlue rounded-md text-navyBlue focus:outline-none mb-4"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="phone"
                placeholder="Entrez votre téléphone"
                className="p-2 bg-transparent border-2 border-navyBlue rounded-md text-navyBlue focus:outline-none mb-4"
                value={formData.phone}
                onChange={handleChange}
              />
              <input
                type="text"
                name="subject"
                placeholder="Objet de votre demande"
                className="p-2 bg-transparent border-2 border-navyBlue rounded-md text-navyBlue focus:outline-none mb-4"
                value={formData.subject}
                onChange={handleChange}
                required
              />
              <textarea
                name="message"
                placeholder="Entrez votre message"
                rows="10"
                className="p-2 font-trebuc bg-transparent border-navyBlue border-2 rounded-md text-navyBlue focus:outline-none mb-4"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
              <button
                type="submit"
                className="text-gray-800 font-trebuc bg-limeGreen px-12 py-4 mx-auto flex items-center rounded-md hover:scale-110 duration-300 md:text-2xl"
              >
                Envoyer
              </button>
              {successMessage && (
                <p className="text-green-600 mt-4">{successMessage}</p>
              )}
              {errorMessage && (
                <p className="text-red-600 mt-4">{errorMessage}</p>
              )}
            </form>
          </div>
        </div>
        <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center md:justify-end h-full px-4 md:flex-row">
          <div className="flex flex-col md:mx-12 mx-auto md:flex md:flex-col items-center md:items-end md:justify-end text-white">
            <div className="bg-gray-800 flex flex-col justify-center md:px-4 md:py-12 mt-8 md:mt-0 z-10 w-full sm:w-3/4 text-center md:w-full md:h-2/3 rounded-xl shadow-2xl mx-auto">
              <img
                src={LogoKevinWhite}
                alt="Logo Kevin"
                className="md:h-96 md:w-96 h-64 w-64 py-4 px-4 mx-auto cursor-pointer"
              />
              <div className="text-white text-lg md:text-2xl font-avocats font-light mb-0">
                <p className="mb-2 mx-6">
                  Groupement d'intérêt économique <br />
                </p>
                <p className="mb-2 mx-4">
                  Courriel :{" "}
                  <strong className="font-bold">
                    contact@polajuris-avocats.fr
                  </strong>
                </p>
                <p className="mb-2 mx-4">
                  Adresse : 89 rue de Créqui 69006 Lyon
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-300 pt-12">
        <Map />
      </div>
    </div>
  );
}

export default Contact;
