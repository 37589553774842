import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Echecs from "../assets/hotelDieuOptimise.jpg"; // ou .webp si tu veux utiliser la version WebP
import "./NavBar.css";
import { Link as ScrollLink } from "react-scroll";

function Home() {
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/actualités");
  };

  useEffect(() => {
    // Pré-chargement de l'image
    const img = new Image();
    img.src = Echecs; // La source de l'image à pré-charger
    img.onload = () => setLoaded(true); // Une fois l'image chargée, on modifie l'état
  }, []);

  return (
    <div
      id="home"
      className="relative min-h-screen flex flex-col items-center justify-center pt-32"
      style={{ backgroundColor: loaded ? "transparent" : "#1e3a8a" }} // Fond bleu par défaut
    >
      {/* Image de fond avec transition */}
      <div
        className={`absolute top-0 left-0 w-full h-full bg-cover bg-no-repeat bg-center transition-all duration-500 ${
          loaded ? "opacity-100" : "opacity-95"
        }`}
        style={{
          backgroundImage: `url(${Echecs})`,
          backgroundPosition: "center 20%", // Ajuste ce pourcentage pour déplacer l'image
        }}
      ></div>

      {/* Contenu texte avec la boîte */}
      <div className="relative z-10 bg-black bg-opacity-70 p-6 md:p-12 rounded-xl w-3/4 md:w-full max-w-2xl mx-auto text-center md:mt-2 mt-24 mb-12">
        {" "}
        <p className="text-limeGreen text-3xl md:text-5xl font-Bayer mb-4 cursor-pointer hover:scale-105 transition-transform duration-1000">
          Polajuris <br />
        </p>
        <p className="text-lightGray text-sm md:text-xl font-avocats leading-relaxed mb-8">
          Le GIE POLAJURIS réunit Marine BRUNIERE et Arnaud DEPENAU, avocats au
          barreau de Lyon. Ils mettent en commun leurs compétences en droit
          public et proposent un accompagnement complet aux administrations
          ainsi qu'aux porteurs de projets privés et publics.
          <br />
          <br />
          Familiers de la gestion d'une clientèle institutionnelle et attachés à
          un accompagnement personnalisé, les membres du GIE POLAJURIS
          garantissent une prise en charge adaptée à vos besoins. Pour ce faire,
          ils vous conseillent et vous accompagnent devant l'ensemble des
          juridictions judiciaires, administratives ou professionnelles.
        </p>
        <ScrollLink
          to="contact"
          spy={true}
          smooth={true}
          offset={-70}
          duration={1000}
        >
          <button className="text-navyBlue bg-limeGreen font-arial px-8 py-4 mx-auto flex items-center rounded-sm shadow-2xl hover:scale-110 duration-300 md:text-xl mt-8 uppercase font-semibold">
            Rencontrons nous !
          </button>
        </ScrollLink>
        <button
          onClick={handleNavigate}
          className="relative z-10 text-navyBlue bg-gray-300 font-arial px-6 py-2  rounded-sm shadow-2xl hover:scale-110 duration-300 md:text-xl mt-4  font-semibold"
        >
          Actualités
        </button>
      </div>

      {/* Bouton Actualités centré sous la boîte */}
    </div>
  );
}

export default Home;
