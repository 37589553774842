import React, { useEffect } from "react";

function MentionsLegales() {
  useEffect(() => {
    // Empêcher le scrolling lorsque le composant est monté
    document.body.style.overflow = "hidden";

    // Rétablir le scrolling lorsque le composant est démonté
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="bg-gray-800 rounded-md p-4 w-full h-screen text-left mx-auto my-0 overflow-auto">
      <div className="flex justify-center mt-44">
        <button
          className="text-limeGreen hover:text-white focus:outline-none"
          onClick={() => window.history.back()}
        >
          Retour au site
        </button>
      </div>
      <div className="flex flex-col items-start lg:mx-56 justify-center text-left lg:my-24 my-8 font-trebuc text-xl text-gray-300">
        <p className="mb-10 font-seasons uppercase text-limeGreen font-bold">
          Mentions Légales
        </p>

        <p className="font-seasons mb-2 font-semibold text-limeGreen ">
          PRÉSENTATION DU SITE
        </p>
        <p className="mb-6 text-left font-avocats">
          Ce site internet est édité par : <br />
          Arnaud Depenau, Avocat au Barreau de Lyon, entrepreneur individuel
          sous le numéro SIREN 834 306 706. <br />
          Adresse professionnelle : 74 rue de Bonnel, 69003 Lyon. <br />
          Courriel :{" "}
          <a
            href="mailto:a.depenau@depenau-avocat.fr"
            className="text-gray-400 hover:text-white"
          >
            a.depenau@depenau-avocat.fr
          </a>{" "}
          <br />
          Téléphone : +33 (0)6.52.17.47.41
          <br /> Marine Brunière, Avocat au Barreau de Lyon, entrepreneure
          individuelle sous le numéro SIREN 892 638 958 000 25
          <br />
          Courriel :{" "}
          <a
            href="contact@bruniere-avocat.com"
            className="text-gray-400 hover:text-white"
          >
            contact@bruniere-avocat.com{" "}
          </a>{" "}
          <br />
          Téléphone : +33 (0)6.43.44.23.80
        </p>

        <p className="mb-2 font-semibold text-limeGreen  font-seasons">
          HÉBERGEMENT
        </p>
        <p className="mb-6 font-avocats">
          Ce site internet est hébergé par la société OVH, SAS au capital de 10
          069 020 €. <br />
          RCS Lille Métropole 424 761 419 00045. <br />
          Code APE 2620Z, N° TVA : FR 22 424 761 419. <br />
          Siège social : 2 rue Kellermann, 59100 Roubaix, France. <br />
          Téléphone : 09 72 10 10 07
        </p>

        <p className="mb-2 font-semibold text-limeGreen  font-seasons">
          CRÉDITS CRÉATION
        </p>
        <p className="mb-6 font-avocats">
          Direction artistique, conception et réalisation : <br />
          <p className="text-gray-400 hover:text-white">Arnaud Champetier </p>
          . <br />
          Contact :{" "}
          <a
            href="arnaud.champetier9@gmail.com"
            className="text-gray-400 hover:text-white"
          >
            arnaud.champetier9@gmail.com{" "}
          </a>
        </p>

        <p className="mb-2 font-semibold text-limeGreen  font-seasons">
          DROITS DE PROPRIÉTÉ INTELLECTUELLE
        </p>
        <p className="mb-6 font-avocats">
          Maître Arnaud Depenau et Maître Marine Brunière disposent et/ou
          détiennent l’ensemble des droits de propriété intellectuelle relatifs
          au site internet et aux différents éléments qui le composent, tels que
          notamment les textes, les photographies et les logos. <br />
          L’usage de tout ou partie du site internet, que ce soit par voie de
          reproduction, de transmission, de représentation ou de diffusion,
          visant d’autres fins que celles d’un usage personnel privé est
          strictement interdit.
        </p>

        <p className="mb-2 font-semibold text-limeGreen  font-seasons">
          MÉDIATEUR DE LA CONSOMMATION DE LA PROFESSION D’AVOCAT (SI CLIENT
          CONSOMMATEUR)
        </p>
        <p className="mb-6 font-avocats">
          Les clients – consommateurs, à savoir les personnes physiques qui
          agissent à titre privé, à des fins qui n’entrent pas dans le cadre de
          leur activité commerciale, industrielle, artisanale ou libérale, sont
          informés de la possibilité offerte par l’article L. 612-1 du Code de
          la consommation, en cas de litige avec Maître Arnaud Depenau, d’avoir
          recours au médiateur de la consommation de la profession d’Avocat dont
          les coordonnées sont les suivantes :
        </p>
        <p className="mb-6 font-avocats">
          180 boulevard Haussmann, 75008 PARIS <br />
          <a
            href="mailto:mediateur-conso@mediateur-consommation-avocat.fr"
            className="text-gray-400 hover:text-white"
          >
            mediateur-conso@mediateur-consommation-avocat.fr
          </a>
        </p>
        <p className="mb-6 font-avocats text-limeGreen ">
          La saisine du médiateur ne peut intervenir qu’après avoir tenté au
          préalable de résoudre le litige directement auprès de Maître Arnaud
          Depenau par une réclamation écrite.
        </p>
      </div>
    </div>
  );
}

export default MentionsLegales;
