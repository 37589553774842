import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { articles } from "./Actualités"; // Importe les articles ici
import Back from "../assets/back1.png"; // Icône back

// Fonction pour générer un slug
const generateSlug = (title) => {
  return title
    .normalize("NFD") // Supprime les accents
    .replace(/[\u0300-\u036f]/g, "") // Supprime les diacritiques
    .replace(/[^\w\s-]/g, "") // Supprime les emojis et caractères spéciaux
    .trim()
    .replace(/\s+/g, "-") // Remplace les espaces par des tirets
    .toLowerCase();
};

function Article() {
  const { slug } = useParams();
  const navigate = useNavigate(); // Pour la redirection

  // On génère les slugs pour les articles et on cherche le bon article
  const article = articles.find((art) => generateSlug(art.title) === slug);

  // Si on ne trouve pas l'article
  if (!article) {
    return <h1 className="text-center text-red-500">Article non trouvé</h1>;
  }

  return (
    <div className="max-w-3xl mx-auto p-6 mt-36 md:mt-24 relative">
      {/* Flèche retour en haut à gauche */}
      <button
        onClick={() => navigate("/actualités")}
        className="absolute top-6 left-6 p-2 bg-transparent rounded-full hover:scale-110 transition-transform duration-300"
        aria-label="Retour aux actualités"
      >
        <img src={Back} alt="" className="w-6 h-6"></img>
      </button>

      {/* Titre de l'article */}
      <h1 className="text-3xl font-bold mb-12 text-justify mt-10">
        {article.title}
      </h1>

      {/* Image de l'article */}
      <img
        src={article.image}
        alt={article.title}
        className="w-full max-w-2xl mx-auto object-contain my-4"
      />

      {/* Date de création */}
      <p className="font-avocats bottom-0 left-0 mb-12 ml-0 text-gray-500 text-sm">
        {article.createdAt}
      </p>

      {/* Contenu de l'article */}
      <div className="article-content space-y-4 leading-relaxed">
        {article.content.map((paragraph, index) => (
          <p key={index} className="text-gray-700 text-justify font-avocats">
            {paragraph.bold ? (
              <strong>{paragraph.text}</strong>
            ) : (
              paragraph.text
            )}
          </p>
        ))}
      </div>

      {/* Bouton retour aux actualités en bas */}
      <button
        onClick={() => navigate("/actualités")}
        className="mt-16 px-6 py-2 bg-limeGreen text-navyBlue font-semibold font-avocats rounded-md hover:bg-blue-600 mx-auto block md:mx-0"
      >
        Retour aux actualités
      </button>
    </div>
  );
}

export default Article;
