import React from "react";

function Footer() {
  return (
    <div className="bg-gray-800">
      <p className="text-center text-white text-sm py-4 text-trebuc">
        GIE Polajuris - Tous droits réservés ®{" "}
      </p>
      <div className="mt-0 text-center">
        <a
          href="/mentionslegales"
          className="text-white font-trebuc hover:text-white focus:outline-none"
        >
          Mentions légales
        </a>
      </div>
    </div>
  );
}

export default Footer;
