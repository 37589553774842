import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import { FaBars, FaTimes } from "react-icons/fa";
import LogoKevinWhite from "../assets/LogoPola6.png";

function NavBar() {
  const [nav, setNav] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavToggle = () => {
    setNav(!nav);
  };

  const handleNavClick = (target) => {
    setNav(false); // Ferme le menu burger immédiatement

    if (location.pathname === "/") {
      // ✅ On est sur l'accueil -> Scroll direct
      scroller.scrollTo(target, {
        duration: 1000,
        smooth: true,
        offset: -70,
      });
    } else {
      // ✅ On est sur /actualités -> Change d’URL et scrolle après le chargement
      navigate(`/#${target}`);
    }
  };

  useEffect(() => {
    if (location.hash) {
      const target = location.hash.replace("#", "");
      scroller.scrollTo(target, {
        duration: 1000,
        smooth: true,
        offset: -70,
      });
    }
  }, [location]);

  const handleLogoClick = () => {
    if (location.pathname === "/") {
      // ✅ On est déjà sur / → On scrolle en haut au lieu de recharger
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      // ✅ On est sur /actualités → On retourne sur /
      navigate("/");
    }
  };

  const handleMenuItemClick = (target) => {
    setNav(false); // Ferme le menu burger lors d'un clic
    if (target === "/actualités") {
      navigate(target); // Redirige vers la page Actualités
    } else {
      handleNavClick(target); // Scroll si section
    }
  };

  const links = [
    { id: 1, link: "Qui sommes-nous ?", target: "cabinet" },
    { id: 2, link: "Honoraires", target: "honoraires" },
    { id: 3, link: "Actualités", target: "/actualités" }, // Accent corrigé
    { id: 4, link: "Contactez-nous", target: "contact" },
  ];

  return (
    <div className="fixed top-0 left-0 w-full z-50">
      <div className="flex items-center w-full h-32 px-6 text-white bg-transparent">
        <div className="flex items-center space-x-4">
          {/* Menu Burger */}
          <div
            onClick={handleNavToggle}
            className="cursor-pointer text-limeGreen text-4xl"
          >
            {nav ? <FaTimes /> : <FaBars />}
          </div>

          {/* Logo - Ramène à l'accueil OU scrolle vers le haut */}
          <img
            src={LogoKevinWhite}
            alt="Logo Kevin"
            className="h-48 w-48 md:h-64 md:w-64 cursor-pointer transition-transform duration-1000 ease-in-out hover:scale-105 hover:opacity-90"
            onClick={handleLogoClick}
          />
        </div>
      </div>

      {/* Menu Burger */}
      <ul
        className={`${
          nav
            ? "flex flex-col justify-center items-center fixed inset-0 bg-black bg-opacity-80 text-white p-8"
            : "hidden"
        }`}
      >
        {links.map(({ id, link, target }) => (
          <li
            key={id}
            className="px-4 cursor-pointer text-xl text-limeGreen font-Bayer my-6 hover-scale-105 duration-300"
            onClick={() => handleMenuItemClick(target)} // Fermeture menu et redirection ou scroll
          >
            {link}
          </li>
        ))}

        {/* Bouton fermer */}
        <li
          className="absolute top-4 right-4 text-limeGreen text-4xl cursor-pointer"
          onClick={handleNavToggle}
        >
          <FaTimes />
        </li>
      </ul>
    </div>
  );
}

export default NavBar;
